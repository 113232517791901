import { BaseAction } from '../../../base/IBaseActionRespone';
import { dm_coso } from '../../../models/mega/dm_coso';
import { dm_khoi } from '../../../models/mega/dm_khoi';
import { dm_namhoc } from '../../../models/mega/dm_namhoc';
import { dm_truong } from '../../../models/mega/dm_truong';
import { dm_tu_van_viens } from '../../../models/mega/dm_tuvanvien';

import {
	CategoryAction_GETCOSO_Failure,
	CategoryAction_GETCOSO_Request,
	CategoryAction_GETCOSO_Success,
	CategoryAction_GETKHOI_Failure,
	CategoryAction_GETKHOI_Request,
	CategoryAction_GETKHOI_Success,
	CategoryAction_GETNAMHOC_Failure,
	CategoryAction_GETNAMHOC_Request,
	CategoryAction_GETNAMHOC_Success,
	CategoryAction_GETTRUONG_Failure,
	CategoryAction_GETTRUONG_Request,
	CategoryAction_GETTRUONG_Success,
	CategoryAction_GETTUVANVIEN_Failure,
	CategoryAction_GETTUVANVIEN_Request,
	CategoryAction_GETTUVANVIEN_Success,
	eCategoryActionTypeIds,
} from './ICategoryActionTypes';

export const categoryActions = {
	//ALL
	getCOSORequest: (): CategoryAction_GETCOSO_Request => BaseAction(eCategoryActionTypeIds.GET_COSO_REQUEST, undefined),
	getCOSOSuccess: (data: dm_coso[]): CategoryAction_GETCOSO_Success => BaseAction(eCategoryActionTypeIds.GET_COSO_SUCCESS, data),
	getCOSOFailure: (error: Error | string): CategoryAction_GETCOSO_Failure => BaseAction(eCategoryActionTypeIds.GET_COSO_FAILURE, error),
	getTRUONGRequest: (): CategoryAction_GETTRUONG_Request => BaseAction(eCategoryActionTypeIds.GET_TRUONG_REQUEST, undefined),
	getTRUONGSuccess: (data: dm_truong[]): CategoryAction_GETTRUONG_Success => BaseAction(eCategoryActionTypeIds.GET_TRUONG_SUCCESS, data),
	getTRUONGFailure: (error: Error | string): CategoryAction_GETTRUONG_Failure =>
		BaseAction(eCategoryActionTypeIds.GET_TRUONG_FAILURE, error),
	getKHOIRequest: (): CategoryAction_GETKHOI_Request => BaseAction(eCategoryActionTypeIds.GET_KHOI_REQUEST, undefined),
	getKHOISuccess: (data: dm_khoi[]): CategoryAction_GETKHOI_Success => BaseAction(eCategoryActionTypeIds.GET_KHOI_SUCCESS, data),
	getKHOIFailure: (error: Error | string): CategoryAction_GETKHOI_Failure => BaseAction(eCategoryActionTypeIds.GET_KHOI_FAILURE, error),
	getNAMHOCRequest: (): CategoryAction_GETNAMHOC_Request => BaseAction(eCategoryActionTypeIds.GET_NAMHOC_REQUEST, undefined),
	getNAMHOCSuccess: (data: dm_namhoc[]): CategoryAction_GETNAMHOC_Success => BaseAction(eCategoryActionTypeIds.GET_NAMHOC_SUCCESS, data),
	getNAMHOCFailure: (error: Error | string): CategoryAction_GETNAMHOC_Failure =>
		BaseAction(eCategoryActionTypeIds.GET_NAMHOC_FAILURE, error),
	getTUVANVIENRequest: (): CategoryAction_GETTUVANVIEN_Request => BaseAction(eCategoryActionTypeIds.GET_TUVANVIEN_REQUEST, undefined),
	getTUVANVIENSuccess: (data: dm_tu_van_viens[]): CategoryAction_GETTUVANVIEN_Success =>
		BaseAction(eCategoryActionTypeIds.GET_TUVANVIEN_SUCCESS, data),
	getTUVANVIENFailure: (error: Error | string): CategoryAction_GETTUVANVIEN_Failure =>
		BaseAction(eCategoryActionTypeIds.GET_TUVANVIEN_FAILURE, error),
};
