/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { AppState } from '../../../context/stores/rootReducers';
import { useSelector } from 'react-redux';
import { BaseResponse } from '../../../context/base/BaseResponse';
import { rootApi } from '../../../context/api/rootApi';
import { HocSinhBaoCaoTongQuanResponse } from '../../../context/models/mega/HocSinhBaoCaoTongQuanResponse';
import BaoCaoHocSinhPieChart from '../baocao-theokhoi/BaoCaoHocSinhPieChart';
import BaoCaoHocSinhTheoHePieChart from './BaoCaoHocSinhKetQuaHocTapPieChart';
import BaoCaoSucKhoeHocSinhChart from '../baocao-theokhoi/BaoCaoSucKhoeHocSinhChart';
import BaoCaoHocSinhTheoKhoi from '../baocao-theokhoi/BaoCaoHocSinhTheoKhoi';
import { Loading } from '../../../components/base';
import { Loadingv2 } from '../../../components/base/Loadingv2';
import BaoCaoHocSinhKetQuaHocTapPieChart from './BaoCaoHocSinhKetQuaHocTapPieChart';
import { NoDataLoading } from '../../../components/base/NoDataLoading';
import PopupKetQuaHocTapBox from './PopupKetQuaHocTapBox';

interface BaoCaoHocSinhKqhtTableResponse {
	dm_xep_hangs: string[],
	dm_hoc_sinh_xep_hangs: HocSinhKqhtChiTietResponse[]
}

interface HocSinhKqhtChiTietResponse {
	ten_truong: string;
	ten_truong_en: string;
	ten_khoi: string;
	ten_khoi_en: string;
	ten_he: string;
	ten_he_en: string;
	ten_lop: string;
	ten_lop_en: string;
	rowspanKhoi: number;
	rowspanHe: number;
	so_luongs: number[];
	dm_lop_id: number;
}

const BaoCaoHocSinhKetQuaHocTapBox = () => {
	const { dm_coso_id, dm_truong_id, nam_hoc, hoc_ky } = useSelector((state: AppState) => state.common);
	const [dataReport, setDataReport] = useState<BaoCaoHocSinhKqhtTableResponse>();
	const [isLoadingTableTheoHe, SetIsLoadingTableTheoHe] = useState(true);
	const [isShowPopup, setShowPopup] = useState(false);
	const [idLop, setIdLop] = useState(0);
	const [xepHang, setXepHang] = useState('');

	const handleReloadData = useCallback(async () => {
		SetIsLoadingTableTheoHe(true)
		const res: BaseResponse = await rootApi.baoCao.SelectTableKetQuaHocTapAsync(dm_coso_id, dm_truong_id, 0, nam_hoc, hoc_ky);
		if (res.isSuccess && res.result) {
			const listCategory = res.result as BaoCaoHocSinhKqhtTableResponse;
			setDataReport(listCategory);
		} else {
			setDataReport(undefined);
		}
		SetIsLoadingTableTheoHe(false);
	}, [dm_truong_id, nam_hoc, hoc_ky]);

	useEffect(() => {
		handleReloadData();
	}, [dm_truong_id, nam_hoc, hoc_ky]);

	const onClose = () => {
		setShowPopup(false);
	}

	const onOpenPopup = (idLopTmp: number, ten_xep_hang: string) => {
		setShowPopup(true)
		setIdLop(idLopTmp)
		setXepHang(ten_xep_hang)
	}

	return (
		<>
			{/* <!-- Tổng số học sinh box (tỉ lệ 1) --> */}
			<div className="box box-1">
				<BaoCaoHocSinhKetQuaHocTapPieChart />
			</div>
			{/* <!-- Biểu đồ box (tỉ lệ 2) --> */}
			<div className="box box-2 no-margin">
				{!isLoadingTableTheoHe && dataReport?.dm_xep_hangs &&
					<>
						<div className="title">Chi tiết xếp loại danh hiệu học sinh</div>
					</>
				}
				{!isLoadingTableTheoHe && !dataReport?.dm_xep_hangs && <NoDataLoading />}
				<div className="table-container">
					{isLoadingTableTheoHe &&
						<Loadingv2 />
					}
					{!isLoadingTableTheoHe && dataReport?.dm_xep_hangs &&
						<table>
							<thead>
								<tr>
									<th>Khối</th>
									<th>Hệ</th>
									<th className="text-center">Lớp</th>
									{dataReport?.dm_xep_hangs && dataReport?.dm_xep_hangs.map((item) =>
										<th className="text-center">{item}</th>
									)}
								</tr>
							</thead>
							<tbody>
								{dataReport?.dm_hoc_sinh_xep_hangs && dataReport?.dm_hoc_sinh_xep_hangs.map((item) =>
									<tr className="expandable-row">
										<td rowSpan={item.rowspanKhoi} hidden={item.rowspanKhoi === 0}>{item.ten_khoi}</td>
										<td rowSpan={item.rowspanHe} hidden={item.rowspanHe === 0}>{item.ten_he}</td>
										<td className="text-center">{item.ten_lop}</td>
										{item.so_luongs && item.so_luongs.map((itemXh, index) =>
											<>
												<td className="text-center" hidden={itemXh <= 0}><a href='#' onClick={() => onOpenPopup(item.dm_lop_id, dataReport?.dm_xep_hangs[index])}>{itemXh}</a></td>
												<td className="text-center" hidden={itemXh > 0}>-</td>
											</>
										)}
									</tr>
								)}
							</tbody>
						</table>
					}
				</div>
			</div>
			{isShowPopup && <PopupKetQuaHocTapBox dm_lop_id={idLop} xep_hang={xepHang} onClose={onClose} />}
		</>
	);
};

export default BaoCaoHocSinhKetQuaHocTapBox;
