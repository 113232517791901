/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import imgButton from '../../../assets/images/button.svg';
import { rootApi } from '../../../context/api/rootApi';
import { BaseResponse } from '../../../context/base/BaseResponse';
import { AppState } from '../../../context/stores/rootReducers';
import { Loadingv2 } from '../../../components/base/Loadingv2';

interface BaoCaoHocSinhTheoKhoiResponse {
	dm_truong_id: number;
	dm_khoi_id: number;
	dm_he_id: number;
	ten_truong: string;
	ten_truong_en: string;
	ten_khoi: string;
	ten_khoi_en: string;
	ten_he: string;
	ten_he_en: string;
	rowspanHe: number,
	rowspanKhoi: number,
	dm_lop_id: number,
	ten_lop: string;
	chua_diem_danh: number;
	trang_thai: number;
	giao_vien_phu_trach: string;
	gio_diem_danh: string;
	ten_hoc_sinh: string;
	ghi_chu: string;
	ngay_diem_danh: string;
	ma_hoc_sinh: string;
}

interface PopUpDiemDanhNgayProps {
	onClose: () => void;
}

const PopupXuLyVangMatBox = (props: PopUpDiemDanhNgayProps) => {
	const { dm_coso_id, dm_truong_id, nam_hoc, tu_ngay, den_ngay } = useSelector((state: AppState) => state.common);
	const [dataReport, setDataReport] = useState<BaoCaoHocSinhTheoKhoiResponse[]>([]);
	const [isLoadingTableTheoHe, SetIsLoadingTableTheoHe] = useState(true);

	const handleReloadData = useCallback(async () => {
		SetIsLoadingTableTheoHe(true)
		const res: BaseResponse = await rootApi.baoCao.SelectPopupXuLyVangKhongPhepAsync(dm_coso_id, dm_truong_id, nam_hoc, tu_ngay, den_ngay);
		if (res.isSuccess && res.result) {
			const listCategory = res.result as BaoCaoHocSinhTheoKhoiResponse[];
			setDataReport(listCategory);
		} else {
			setDataReport([]);
		}
		SetIsLoadingTableTheoHe(false);
	}, [dm_coso_id, nam_hoc, dm_truong_id, tu_ngay, den_ngay]);

	useEffect(() => {
		handleReloadData();
	}, [dm_coso_id, nam_hoc, dm_truong_id, tu_ngay, den_ngay]);

	return (
		<>
			{/* <!-- chi tiet diem danh --> */}
			<div className="modal fade diemdanh-detail show" id="myModal" role="dialog">
				<div className="modal-dialog modal-lg">
					<div className="col-xs-12 modal-content">
						<div className="col-xs-12 modal-header">
							<h4 className="modal-title">Danh sách học sinh vắng không phép</h4>
							<button type="button" className="close" onClick={props.onClose}><img src={imgButton} /></button>
						</div>
						<div className="col-xs-12 modal-body">
							<div className="col-xs-12 table-container">
								{isLoadingTableTheoHe &&
									<Loadingv2 />
								}
								{!isLoadingTableTheoHe && dataReport && dataReport.length > 0 &&
									<table id="attendanceTable">
										<thead>
											<tr>
												<th style={{ width: '50px' }}>Ngày điểm danh</th>
												<th style={{ width: '50px' }}>Mã học sinh</th>
												<th>Họ tên</th>
												<th>Hệ</th>
												<th className="text-center">Lớp</th>
												<th>GV phụ trách</th>
												<th style={{ width: '50px' }}>Giờ điểm danh</th>
												<th style={{ width: '50px' }}>Trạng thái</th>
												<th>Ghi chú</th>
											</tr>
										</thead>
										<tbody>
											{dataReport.map((item) =>
												<tr className="expandable-row">
													<td>{moment(item.ngay_diem_danh).format('DD/MM/yyyy')}</td>
													<td>{item.ma_hoc_sinh}</td>
													<td>{item.ten_hoc_sinh}</td>
													<td>{item.ten_he}</td>
													<td>{item.ten_lop}</td>
													<td>{item.giao_vien_phu_trach}</td>
													<td>{item.gio_diem_danh}</td>
													{item.ghi_chu === '' && <td className="text-center not-attendance">Chưa xác nhận</td>}
													{item.ghi_chu !== '' && <td className="text-center attendance">Đã xác nhận</td>}
													<td className="text-center">{item.ghi_chu}</td>
												</tr>
											)}
										</tbody>
									</table>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <!-- end chi tiet diem danh --> */}
		</>
	);
};

export default PopupXuLyVangMatBox;
