/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import imgButton from '../../../assets/images/button.svg';
import { rootApi } from '../../../context/api/rootApi';
import { BaseResponse } from '../../../context/base/BaseResponse';
import { AppState } from '../../../context/stores/rootReducers';
import { Loadingv2 } from '../../../components/base/Loadingv2';

interface DataResponse {
	dm_truong_id: number;
	dm_khoi_id: number;
	dm_he_id: number;
	ten_truong: string;
	ten_truong_en: string;
	ten_khoi: string;
	ten_khoi_en: string;
	ten_he: string;
	ten_he_en: string;
	rowspanHe: number,
	rowspanKhoi: number,
	dm_lop_id: number,
	ten_lop: string;
	trang_thai: number;
	ten_hoc_sinh: string;
	gioi_tinh: string;
	ngay_sinh: string;
	ngay_nhap_hoc: string;
	ma_hoc_sinh: string;
}

interface PopUpProps {
	onClose: () => void;
	trang_thai: number;
	dm_he_id: number;
	dm_khoi_id: number;
}

const PopupHocSinhTheoTrangThaiBox = (props: PopUpProps) => {
	const { dm_coso_id, dm_truong_id, nam_hoc } = useSelector((state: AppState) => state.common);
	const [dataReport, setDataReport] = useState<DataResponse[]>([]);
	const [isLoadingTableTheoHe, SetIsLoadingTableTheoHe] = useState(true);

	const handleReloadData = useCallback(async () => {
		SetIsLoadingTableTheoHe(true)
		const res: BaseResponse = await rootApi.baoCao.SelectPopupNghiHocBaoLuuAsync(dm_coso_id, dm_truong_id, nam_hoc, props.dm_he_id, props.trang_thai, props.dm_khoi_id);
		if (res.isSuccess && res.result) {
			const listCategory = res.result as DataResponse[];
			setDataReport(listCategory);
		} else {
			setDataReport([]);
		}
		SetIsLoadingTableTheoHe(false);
	}, [dm_coso_id, nam_hoc, dm_truong_id, props.dm_he_id, props.trang_thai]);

	useEffect(() => {
		handleReloadData();
	}, [dm_coso_id, nam_hoc, dm_truong_id, props.dm_he_id, props.trang_thai]);

	return (
		<>
			{/* <!-- chi tiet diem danh --> */}
			< div className="modal fade diemdanh-detail show" id="myModal" role="dialog">
				<div className="modal-dialog modal-lg">
					<div className="col-xs-12 modal-content">
						<div className="col-xs-12 modal-header">
							<h4 className="modal-title">Chi tiết danh sách học sinh theo trạng thái</h4>
							<button type="button" className="close" onClick={props.onClose}><img src={imgButton} /></button>
						</div>
						<div className="col-xs-12 modal-body">
							<div className="col-xs-12 table-container">
								{isLoadingTableTheoHe &&
									<Loadingv2 />
								}
								{!isLoadingTableTheoHe && dataReport && dataReport.length > 0 &&
									<table id="attendanceTable">
										<thead>
											<tr>
												<th>Hệ</th>
												<th className="text-center">Lớp</th>
												<th style={{ width: '50px' }}>Mã học sinh</th>
												<th>Họ tên</th>
												<th style={{ width: '50px' }}>Ngày sinh</th>
												<th style={{ width: '50px' }}>Giới tính</th>
												<th style={{ width: '50px' }}>Ngày nhập học</th>
											</tr>
										</thead>
										<tbody>
											{dataReport.map((item) =>
												<tr className="expandable-row">
													<td>{item.ten_he}</td>
													<td>{item.ten_lop}</td>
													<td>{item.ma_hoc_sinh}</td>
													<td>{item.ten_hoc_sinh}</td>
													<td>{moment(item.ngay_sinh).format('DD/MM/yyyy')}</td>
													<td>{item.gioi_tinh}</td>
													{item.ngay_nhap_hoc && <td>{moment(item.ngay_nhap_hoc).format('DD/MM/yyyy')}</td>}
													{!item.ngay_nhap_hoc && <td>-</td>}
												</tr>
											)}
										</tbody>
									</table>
								}
							</div>
						</div>
					</div>
				</div>
			</div >
			{/* <!-- end chi tiet diem danh --> */}
		</>
	);
};

export default PopupHocSinhTheoTrangThaiBox;
