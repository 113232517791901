import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DxDateBox } from '../../components/base';
import { HeaderSelectBoxCoSo, HeaderSelectBoxNamHoc, HeaderSelectBoxTruong } from '../../components/shared/mega';
import { actions } from '../../context/stores/rootActions';

interface IHeaderProps {
}
const HeaderDiemDanh = (props: IHeaderProps) => {

	const dispatch = useDispatch();
	const [tuNgay, setTuNgay] = useState(moment().format('yyyy-MM-DD'));
	const [denNgay, setDenNgay] = useState(moment().format('yyyy-MM-DD'));

	useEffect(() => {
		dispatch(actions.common.changeTuNgay(moment(tuNgay).format('yyyy-MM-DD')));
		dispatch(actions.common.changeDenNgay(moment(denNgay).format('yyyy-MM-DD')));
	}, [tuNgay, denNgay]);
	return (
		<>
			<div className="col-xs-12 form_inline form-diemdanh">
				<div className="col-xs-3 form-group box-diemdanh-2">
					<label>Cơ sở</label>
					<div className="custom-dropdown">
						<HeaderSelectBoxCoSo />
					</div>
				</div>
				<div className="col-xs-3 form-group box-diemdanh-3">
					<label>Cấp học</label>
					<div className="custom-dropdown">
						<HeaderSelectBoxTruong />
					</div>
				</div>
				<div className="col-xs-3 form-group box-diemdanh-4">
					<label>Năm học</label>
					<div className="custom-dropdown">
						<HeaderSelectBoxNamHoc />
					</div>
				</div>
				<div className="col-xs-3 form-group box-diemdanh-4">
					<label>Từ ngày</label>
					<div className="custom-dropdown">
						<DxDateBox
							name='filter_date'
							onValueChanged={(e) => {
								setTuNgay(moment(e).format('yyyy-MM-DD'));
							}}
							placeholder='Chọn ngày điểm danh'
							value={tuNgay}
						/>
					</div>
				</div>
				<div className="col-xs-3 form-group box-diemdanh-4">
					<label>Đến ngày</label>
					<div className="custom-dropdown">
						<DxDateBox
							name='filter_date'
							onValueChanged={(e) => {
								setDenNgay(moment(e).format('yyyy-MM-DD'));
							}}
							placeholder='Chọn ngày điểm danh'
							value={denNgay}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default HeaderDiemDanh;
