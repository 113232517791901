import BaoCaoTuyenSinh from './pages/baobao-tuyensinh/BaoCaoTuyenSinh';
import DashboardDiemDanhPage from './pages/dashboard-diemdanh';
import DashboardHocSinhPage from './pages/dashboard-hocsinh';
import BaoCaoDiemDanhPage from './pages/diem-danh';
import BaoCaoDiemDanhBusPage from './pages/diem-danh-bus';
import BaoCaoGiaoVienPage from './pages/giao-vien';
import BaoCaoHocSinhPage from './pages/hoc-sinh';
import { mySystemRoutes } from './pages/my-system/mySystemRoutes';
import PhoDiemPage from './pages/pho-diem';

export type AppComponent = {
	title: string;
	icon?: string;
	path: string;
	component: any;
};

const AppRoutes = [
	{
		title: 'Dashboard',
		path: '/',
		component: DashboardHocSinhPage,
	},
	{
		title: 'Điểm danh',
		path: '/diem-danh',
		component: DashboardDiemDanhPage,
	},
	{
		title: 'Điểm danh 1',
		path: '/diem-danh-1',
		component: BaoCaoDiemDanhPage,
	},
	{
		title: 'Giáo viên',
		path: '/giao-vien',
		component: BaoCaoGiaoVienPage,
	},
	{
		title: 'học sinh',
		path: '/hoc-sinh',
		component: BaoCaoHocSinhPage,
	},
	{
		title: 'Báo cáo tuyển sinh',
		path: '/bao-cao-tuyen-sinh',
		component: BaoCaoTuyenSinh,
	},
	{
		title: 'Báo cáo điểm danh bus',
		path: '/bao-cao-diem-danh',
		component: BaoCaoDiemDanhBusPage,
	},
	{
		title: 'Kết quả học tập',
		path: '/ket-qua-hoc-tap',
		component: PhoDiemPage
	},
	// {
	// 	title: 'khối',
	// 	path: '/khoi-giang-day',
	// 	component: BaoCaoHocSinhPage
	// },
	...mySystemRoutes,
] as AppComponent[];
export default AppRoutes.map((route) => {
	return {
		...route,
		Component: route.component,
	};
});
