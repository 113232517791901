/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { AppState } from '../../../context/stores/rootReducers';
import { useSelector } from 'react-redux';
import { BaseResponse } from '../../../context/base/BaseResponse';
import { rootApi } from '../../../context/api/rootApi';
import { HocSinhBaoCaoTongQuanResponse } from '../../../context/models/mega/HocSinhBaoCaoTongQuanResponse';
import BaoCaoHocSinhPieChart from '../baocao-theokhoi/BaoCaoHocSinhPieChart';
import BaoCaoSucKhoeHocSinhChart from '../baocao-theokhoi/BaoCaoSucKhoeHocSinhChart';
import BaoCaoHocSinhTheoKhoi from '../baocao-theokhoi/BaoCaoHocSinhTheoKhoi';
import { Loading } from '../../../components/base';
import { Loadingv2 } from '../../../components/base/Loadingv2';
import BaoCaoHocSinhTheoTrangThaiPieChart from './BaoCaoHocSinhTheoTrangThaiPieChart';
import PopupHocSinhTheoTrangThaiBox from './PopupHocSinhTheoTrangThaiBox';

interface BaoCaoHocSinhKqhtTableResponse {
	dm_xep_hangs: string[],
	dm_hoc_sinh_xep_hangs: HocSinhKqhtChiTietResponse[]
}

interface HocSinhKqhtChiTietResponse {
	ten_truong: string;
	ten_truong_en: string;
	dm_khoi_id: number;
	ten_khoi: string;
	ten_khoi_en: string;
	ten_he: string;
	ten_he_en: string;
	dm_he_id: number;
	ten_lop: string;
	ten_lop_en: string;
	rowspanKhoi: number;
	rowspanHe: number;
	so_luongs: number[];
}

const BaoCaoHocSinhTheoTrangThaiBox = () => {
	const { dm_coso_id, dm_truong_id, nam_hoc, hoc_ky } = useSelector((state: AppState) => state.common);
	const [dataReport, setDataReport] = useState<BaoCaoHocSinhKqhtTableResponse>();
	const [isLoadingTableTheoHe, SetIsLoadingTableTheoHe] = useState(true);
	const [isShowPopup, setShowPopup] = useState(false);
	const [idHe, setIdHe] = useState(0);
	const [idKhoi, setIdKhoi] = useState(0);
	const [trangThai, setTrangThai] = useState(-1);

	const handleReloadData = useCallback(async () => {
		SetIsLoadingTableTheoHe(true)
		const res: BaseResponse = await rootApi.baoCao.SelectTableNghiHocBaoLuuAsync(dm_coso_id, dm_truong_id, nam_hoc, hoc_ky);
		if (res.isSuccess && res.result) {
			const listCategory = res.result as BaoCaoHocSinhKqhtTableResponse;
			setDataReport(listCategory);
		} else {
			setDataReport(undefined);
		}
		SetIsLoadingTableTheoHe(false);
	}, [dm_truong_id, nam_hoc, hoc_ky]);

	useEffect(() => {
		handleReloadData();
	}, [dm_truong_id, nam_hoc]);

	const onClose = () => {
		setShowPopup(false);
	}

	const onOpenPopup = (idHeTmp: number, trangThaiTmp: number, idKhoiTmp: number) => {
		setShowPopup(true)
		setTrangThai(trangThaiTmp)
		setIdHe(idHeTmp)
		setIdKhoi(idKhoiTmp)
	}

	return (
		<>
			{/* <!-- Tổng số học sinh box (tỉ lệ 1) --> */}
			<div className="box box-1">
				<BaoCaoHocSinhTheoTrangThaiPieChart />
			</div>
			{/* <!-- Biểu đồ box (tỉ lệ 2) --> */}
			<div className="box box-2 no-margin">
				<div className="title">Chi tiết chỉ số học sinh nhập học, nghỉ học, bảo lưu</div>
				<div className="table-container">
					{isLoadingTableTheoHe &&
						<Loadingv2 />
					}
					{!isLoadingTableTheoHe &&
						<table>
							<thead>
								<tr>
									<th>Khối</th>
									<th>Hệ</th>
									{dataReport?.dm_xep_hangs && dataReport?.dm_xep_hangs.map((item) =>
										<th className="text-center">{item}</th>

									)}
								</tr>
							</thead>
							<tbody>
								{dataReport?.dm_hoc_sinh_xep_hangs && dataReport?.dm_hoc_sinh_xep_hangs.map((item) =>
									<tr className="expandable-row">
										{item.ten_khoi !== 'Tổng cộng' && <td rowSpan={item.rowspanKhoi} hidden={item.rowspanKhoi === 0}>{item.ten_khoi}</td>}
										{item.ten_khoi === 'Tổng cộng' && <td style={{ fontWeight: 'bold' }} rowSpan={item.rowspanKhoi} hidden={item.rowspanKhoi === 0}>{item.ten_khoi}</td>}
										<td>{item.ten_he}</td>
										{item.so_luongs && item.so_luongs.map((itemXh, index) =>
											<>
												{item.ten_khoi !== 'Tổng cộng' && <td className="text-center" hidden={itemXh <= 0}><a href='#' onClick={() => onOpenPopup(item.dm_he_id, (index === 0 ? 22 : index === 1 ? 4 : 5), item.dm_khoi_id)}>{itemXh}</a></td>}
												{item.ten_khoi === 'Tổng cộng' && <td style={{ fontWeight: 'bold' }} className="text-center" hidden={itemXh <= 0}>{itemXh}</td>}
												<td className="text-center" hidden={itemXh > 0}>-</td>
											</>
										)}
									</tr>
								)}
							</tbody>
						</table>
					}
				</div>
			</div>
			{isShowPopup && <PopupHocSinhTheoTrangThaiBox trang_thai={trangThai} dm_he_id={idHe} dm_khoi_id={idKhoi} onClose={onClose} />}
		</>
	);
};

export default BaoCaoHocSinhTheoTrangThaiBox;
