import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NoDataLoading } from '../../../components/base/NoDataLoading';
import {
	DxChart,
	DxCommonSeriesSettings,
	DxLabel,
	DxLegend,
	DxSeries,
	DxTooltip
} from '../../../components/common/DxCharts';
import { rootApi } from '../../../context/api/rootApi';
import { BaseResponse } from '../../../context/base/BaseResponse';
import { AppState } from '../../../context/stores/rootReducers';
import { createUUID } from '../../../utils/createUUID';
import PopupXuLyVangMatBox from './PopupXuLyVangMatBox';

interface ChartXuLyVangMatResponse {
	ngay_diem_danh: string;
	xac_nhan: number;
	khong_xac_nhan: number;
}

const customizeText = (arg: any) => {
	return `${arg.valueText} (${arg.percentText})`;
};
const ChartXuLyVangMat = () => {
	const { dm_coso_id, dm_truong_id, nam_hoc, tu_ngay, den_ngay } = useSelector((state: AppState) => state.common);
	const [dataReport, setDataReport] = useState<ChartXuLyVangMatResponse[]>([]);
	const [isLoadingTableTheoHe, SetIsLoadingTableTheoHe] = useState(true);
	const [isShowPopup, setShowPopup] = useState(false);

	const handleReloadData = useCallback(async () => {
		SetIsLoadingTableTheoHe(true)
		const res: BaseResponse = await rootApi.baoCao.SelectChartXuLyVangKhongPhepAsync(dm_coso_id, dm_truong_id, nam_hoc, tu_ngay, den_ngay);
		if (res.isSuccess && res.result) {
			const listCategory = res.result as ChartXuLyVangMatResponse[];
			setDataReport(listCategory);
		} else {
			setDataReport([]);
		}
		SetIsLoadingTableTheoHe(false);
	}, [dm_coso_id, nam_hoc, dm_truong_id, tu_ngay, den_ngay]);

	useEffect(() => {
		handleReloadData();
	}, [dm_coso_id, nam_hoc, dm_truong_id, tu_ngay, den_ngay]);

	function customizeTooltip(arg: { seriesName: string; valueText: string; }) {
		return {
			text: `${arg.seriesName}: ${arg.valueText}`,
		};
	}
	const onClose = () => {
		setShowPopup(false);
	}

	const onOpenPopup = () => {
		setShowPopup(true)

	}
	return (
		<>
			<div className="box box-1 no-margin" style={{ marginRight: 0 }}>
				<div className="title">Xử lý vắng không phép</div>
				<div className="title">
					<a href='#' onClick={onOpenPopup} style={{ border: '1px solid', borderRadius: '20px', paddingLeft: '10px', paddingRight: '10px', float: 'right', fontSize: '14px' }}>
						Xem chi tiết
					</a>
				</div>
				{(!dataReport || dataReport.length === 0) && <NoDataLoading />}
				{dataReport && dataReport.length > 0 &&
					<DxChart id={createUUID()} dataSource={dataReport} className='w-100' palette={['#26CECB', '#EA7D0F']} height={400}>
						<DxCommonSeriesSettings argumentField='thang' type='bar' hoverMode='allArgumentPoints' selectionMode='allArgumentPoints'>
							<DxLabel visible={false}>
							</DxLabel>
						</DxCommonSeriesSettings>
						<DxSeries valueField='xac_nhan' argumentField='ngay_diem_danh' name='Đã xác nhận' type='bar'></DxSeries>
						<DxSeries valueField='khong_xac_nhan' argumentField='ngay_diem_danh' name='Chưa xác nhận' type='bar'></DxSeries>
						<DxLegend orientation='horizontal' verticalAlignment='bottom' horizontalAlignment='center' />
						<DxTooltip
							enabled={true}
							location="edge"
							customizeTooltip={customizeTooltip}
						/>
					</DxChart>
				}
			</div>
			{isShowPopup && <PopupXuLyVangMatBox onClose={onClose} />}
		</>
	);
};

export default ChartXuLyVangMat;
