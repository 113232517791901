import { useEffect, useState } from 'react';
import { useLocation, useOutlet } from 'react-router-dom';
import { NotAccess } from '../../components/container';
import { MenuResponse } from '../../context/models/my-system/accounts/MenuResponse';
import { useAuth } from '../../utils/AuthProvider';
import Header from './Header';
import Sidebar from './Sidebar';
import $ from "jquery"
import BaoCaoTongQuanBox from '../../pages/dashboard-hocsinh/tong-quan/BaoCaoTongQuanBox';
import BaoCaoHocSinhTheoHeBox from '../../pages/dashboard-hocsinh/hoc-sinh-theo-he/BaoCaoHocSinhTheoHeBox';
import BaoCaoHocSinhKetQuaHocTapBox from '../../pages/dashboard-hocsinh/ket-qua-hoc-tap/BaoCaoHocSinhKetQuaHocTapBox';
import BaoCaoHocSinhTheoTrangThaiBox from '../../pages/dashboard-hocsinh/hoc-sinh-theo-trang-thai/BaoCaoHocSinhTheoTrangThaiBox';
import logoSgv from '../../assets/images/logo.svg';
import vietNamSgv from '../../assets/images/vn.png';
import { HeaderSelectBoxHocKy } from '../../components/shared/mega';

const acceptLink = (path: string, menus: MenuResponse[]) => {
	const checkMenu = menus.find((x) => path.includes(x.path));
	return checkMenu ? true : false;
};

const AdminLayout = () => {
	const { menus } = useAuth();
	const outlet = useOutlet();
	const location = useLocation();
	const [showSidebar, setShowSidebar] = useState(false);
	const [showSidebarMobile, setShowSidebarMobile] = useState(false);

	const isAccess = true;// acceptLink(location.pathname, menus);

	useEffect(() => {
		setShowSidebarMobile(false);
	}, [location]);

	useEffect(() => {
		$('.dropdown-selected').on('click', function () {
			// Đóng tất cả các dropdown khác
			$('.dropdown-items.show').not($(this).next()).removeClass('show');

			$(this).next('.dropdown-items').toggleClass('show');
		});

		$('.dropdown-items div').on('click', function () {
			$(this).parent().prev('.dropdown-selected').text($(this).text());
			$(this).parent().removeClass('show');
		});

		$(window).on('click', function (event) {
			if (!$(event.target).hasClass('dropdown-selected')) {
				$('.dropdown-items.show').removeClass('show');
			}
		});

		var heightScreen = $(window).height();
		$(".content-right").height(heightScreen ?? 0);
	}, []);

	return (
		<div className="col-sm-12 col-xs-12 body-content pushmenu-push">
			{/* <!-- content --> */}
			<div className="col-xs-12 content">
				<div className="header-menu">
					<section className="buttonset">
						<div id="nav_list"></div>
					</section>
				</div>
				<div className="col-xs-12 content-index height-content">
					<div className="col-xs-3 content-left">
						<Sidebar />
					</div>
					<div className="col-xs-9 content-right">
						<div className="col-xs-12 header-top">
							<div className="header-top-left">
								<a href="https://portal.megaedu.net.vn"><img src={logoSgv} /></a>
							</div>
							<div className="header-top-right frame-box">
								<div className="col-xs-12 form-group">
									<div className="custom-dropdown">
										<div className="dropdown-selected"><img src={vietNamSgv} />VN</div>
										<div className="dropdown-items">
											<div data-value="1"><img src={vietNamSgv} />VN</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-xs-12 header-container">
							{isAccess ? outlet : <NotAccess />}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default AdminLayout;
