/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Loadingv2 } from '../../../components/base/Loadingv2';
import { rootApi } from '../../../context/api/rootApi';
import { BaseResponse } from '../../../context/base/BaseResponse';
import { AppState } from '../../../context/stores/rootReducers';
import imgDiemDanh1 from '../../../assets/images/imgDiemDanh1.svg';
import imgDiemDanh2 from '../../../assets/images/imgDiemDanh2.svg';
import imgDiemDanh3 from '../../../assets/images/imgDiemDanh3.svg';
import { NoDataLoading } from '../../../components/base/NoDataLoading';
import PopupTinhTrangDiemDanhBox from './PopupTinhTrangDiemDanhBox';

interface BaoCaoHocSinhTheoKhoiResponse {
	dm_truong_id: number;
	dm_khoi_id: number;
	dm_he_id: number;
	ten_truong: string;
	ten_truong_en: string;
	ten_khoi: string;
	ten_khoi_en: string;
	ten_he: string;
	ten_he_en: string;
	rowspanHe: number,
	rowspanKhoi: number,
	dm_lop_id: number,
	ten_lop: string;
	chua_diem_danh: number;
	dung_gio: number;
	den_muon: number;
	vang_co_phep: number;
	vang_khong_phep: number;
	tong_cong: number;

}

const TinhTrangDiemDanhBox = () => {
	const { dm_coso_id, dm_truong_id, nam_hoc, tu_ngay, den_ngay } = useSelector((state: AppState) => state.common);
	const [dataReport, setDataReport] = useState<BaoCaoHocSinhTheoKhoiResponse[]>([]);
	const [isLoadingTableTheoHe, SetIsLoadingTableTheoHe] = useState(true);
	const [isShowPopup, setShowPopup] = useState(false);
	const [idLop, setIdLop] = useState(0);
	const [trangThai, setTrangThai] = useState(-1);

	const handleReloadData = useCallback(async () => {
		SetIsLoadingTableTheoHe(true)
		const res: BaseResponse = await rootApi.baoCao.SelectTableTinhTrangDiemDanhAsync(dm_coso_id, dm_truong_id, nam_hoc, tu_ngay, den_ngay);
		if (res.isSuccess && res.result) {
			const listCategory = res.result as BaoCaoHocSinhTheoKhoiResponse[];
			setDataReport(listCategory);
		} else {
			setDataReport([]);
		}
		SetIsLoadingTableTheoHe(false);
	}, [dm_coso_id, nam_hoc, dm_truong_id, tu_ngay, den_ngay]);

	useEffect(() => {
		handleReloadData();
	}, [dm_coso_id, nam_hoc, dm_truong_id, tu_ngay, den_ngay]);

	const onClose = () => {
		setShowPopup(false);
	}

	const onOpenPopup = (idLopTmp: number, trangThaiTmp: number) => {
		setShowPopup(true)
		setTrangThai(trangThaiTmp)
		setIdLop(idLopTmp)
	}

	return (
		<>
			<div className="col-xs-12 bg-frame">
				<div className="col-xs-12 box-content">
					<div className="box box-2 no-margin">
						{!isLoadingTableTheoHe && dataReport && dataReport.length > 0 &&
							<>
								<div className="title">Chi tiết tình trạng điểm danh</div>
							</>
						}
						{!isLoadingTableTheoHe && (!dataReport || dataReport.length === 0) && <NoDataLoading />}
						<div className="table-container">
							{isLoadingTableTheoHe &&
								<Loadingv2 />
							}
							{!isLoadingTableTheoHe && dataReport && dataReport.length > 0 &&
								<table>
									<thead>
										<tr>
											<th style={{width: '150px'}}>Khối</th>
											<th>Hệ đào tạo</th>
											<th style={{minWidth: '150px'}}>Lớp</th>
											<th className="text-center" style={{width: '50px'}}>Chưa điểm danh</th>
											<th className="text-center" style={{width: '50px'}}>Đúng giờ</th>
											<th className="text-center" style={{width: '50px'}}>Đến muộn</th>
											<th className="text-center" style={{width: '50px'}}>Vắng có phép</th>
											<th className="text-center" style={{width: '50px'}}>Vắng không phép</th>
											<th className="text-center" style={{width: '50px'}}>Tổng cộng</th>
										</tr>
									</thead>
									<tbody>
										{dataReport.map((item) =>
											<tr className="expandable-row">
												<td rowSpan={item.rowspanKhoi} hidden={item.rowspanKhoi === 0}>{item.ten_khoi}</td>
												<td rowSpan={item.rowspanHe} hidden={item.rowspanHe === 0}>{item.ten_he}</td>
												<td className="text-center">{item.ten_lop}</td>
												{item.chua_diem_danh > 0 && <td className="text-center"><a href='#' onClick={() => onOpenPopup(item.dm_lop_id, 0)}>{item.chua_diem_danh}</a></td>}
												{item.chua_diem_danh === 0 && <td className="text-center">{item.chua_diem_danh}</td>}
												{item.dung_gio > 0 && <td className="text-center"><a href='#' onClick={() => onOpenPopup(item.dm_lop_id, 1)}>{item.dung_gio}</a></td>}
												{item.dung_gio === 0 && <td className="text-center">{item.dung_gio}</td>}
												{item.den_muon > 0 && <td className="text-center"><a href='#' onClick={() => onOpenPopup(item.dm_lop_id, 2)}>{item.den_muon}</a></td>}
												{item.den_muon === 0 && <td className="text-center">{item.den_muon}</td>}
												{item.vang_co_phep > 0 && <td className="text-center"><a href='#' onClick={() => onOpenPopup(item.dm_lop_id, 3)}>{item.vang_co_phep}</a></td>}
												{item.vang_co_phep === 0 && <td className="text-center">{item.vang_co_phep}</td>}
												{item.vang_khong_phep > 0 && <td className="text-center"><a href='#' onClick={() => onOpenPopup(item.dm_lop_id, 4)}>{item.vang_khong_phep}</a></td>}
												{item.vang_khong_phep === 0 && <td className="text-center">{item.vang_khong_phep}</td>}
												<td rowSpan={item.rowspanHe} hidden={item.rowspanHe === 0} className="text-center">{item.tong_cong}</td>
											</tr>
										)}
									</tbody>
								</table>
							}
						</div>
					</div>
				</div>
			</div>
			{isShowPopup && <PopupTinhTrangDiemDanhBox trang_thai_diem_danh={trangThai} dm_lop_id={idLop} onClose={onClose} />}
		</>
	);
};

export default TinhTrangDiemDanhBox;
